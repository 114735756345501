
.syncCell {
    color: #fff; 
    font-size: 1rem; 
    border-bottom: 1px solid #4f4f4f;
    font-family:"Montserrat-Medium";
    padding: 10px;
}
.syncNodata{
    text-align: center;
    margin-top: 1%;
}