/* .csy_TableCntainer {
    max-height: 450px; 
    overflow-y: auto;
 } */
 .csy_cntnerBlk {
  height: calc(-110px + 90vh);
  margin-top: 0px;
  overflow: auto;
  background-color: rgb(31, 31, 31);
 }
/* .csy_TableHead{
  position: sticky;
    top: 0;
} */
.csy_TableHead .csy_cell{ 
  font-weight: bold;
}
.csy_cell{
    color: #fff; 
    font-size: 1rem;
    border: 1px solid #4f4f4f;
    font-family:"Montserrat-Medium";
  }
