.header {
  display: flex;
  justify-content: space-between;
}
.header {
  font-family: "Montserrat";
  font-style: normal;
  /* font-weight: 700; */
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.4px;
}
.header div:nth-child(2) {
  cursor: pointer;
  display: flex;
}
.header div:nth-child(2) div:nth-child(2) {
  margin-left: 10px;
}
.newSize {
  position: relative;
  top: 20px;
  margin: 0;
  cursor: pointer;
}
.actionBlk {
  position: relative;
  text-align: center;
  margin: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
}
/* casual param tabs styles :start */

.tabs_head {
  font-family: "Montserrat, Helvetica, sans-serif";
  font-size: 13px;
  font-weight: 500;
  
  text-transform: capitalize !important;
  /* position: relative; */
  /* border: 1px solid red; */
}
.cp_container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #3a3a3a;
}
.cp_left_box {
  /* border: 1px solid red; */
  width: 30%;
}
.cp_right_box {
  display: flex;  
  align-items: center;
}
.cp_right_box p {
  margin: 2px;
}
.cp_dateblk :nth-child(1){
  font-size: 1rem;
  font-weight:bold;
}
.cp_dateblk :nth-child(2){
  font-size: 1rem;
  color: #c7ccd1;
  font-weight:bold;
  font-style: italic;
}
.cp_newsizeblk{
  display: flex;
  justify-content: center;
  align-items: center;
}
.cp_newsizeblk :nth-child(2){
  cursor: pointer;
}
.cp_right_box .configBrdr{
  border-left: 1px solid #3f3f3f;
  height: 41px;
} 
.cp_right_box div {
  margin-left: 10px;
}
/* .od_right_box {
  width: 75%;
} */

/* casual parameter styles :end */

.cpClsIcon {
  position: absolute;
  right: 15px;
  top: 18px;
  cursor: pointer;
  z-index: 1000;
}
.cpClsIcon svg{ 
  cursor: pointer;
}