/* .cal_TableCntainer {
    max-height: 450px; 
    overflow-y: auto;
 } */
 .cal_cntnerBlk {
    height: calc(-110px + 90vh);
    margin-top: 0px;
    overflow: auto;
    background-color: rgb(31, 31, 31);
   }
/* .cal_TableHead{
  position: sticky;
    top: 0;
} */
.cal_TableHead .cal_cell{ 
    font-weight: bold;
}
.cal_cell{
    color: #fff; 
    font-size: 1rem;
    border: 1px solid #4f4f4f;
    font-family:"Montserrat-Medium";
}

.cal_logPgnation{
    margin-top: 10px;   
}
.cal_logPgnation .pagination {
    background-color: #121212 !important;
    border: none;
    color: #e2e2e2;
    font-size: 12px;
    text-decoration: none;
}
.cal_logPgnation .pagination>li>a, .cal_logPgnation .pagination>li>span{
    float: left;
    line-height: 1.42857143;
    margin-left: -1px;
    padding: 6px 12px;
    position: relative;
    background-color: #1f1f1f !important;
    border: none !important;
}

/* .pagination>li>a, .pagination>li>span {

} */
